<template>
  <div>
    <label class="products-page__input-title" for="" v-if="label">{{ label }}</label>
    <div class="input-group">
      <input class="products-page__input" type="text" :value="value" @input="updateValue" v-bind="$attrs" @keyup="keyup">
      <div v-if="!noicon" class="input-group-append">
        <button class="products-page__input-icon" :class="theme" type="button" @click="search"><i :class="icon" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
</template>
<style scoped>
    input:focus {
        outline: none;
        box-shadow: none;
    }
</style>
<script>
export default {
    inheritAttrs: false,
    props: {
        label: {
            type: String
        },
        value: [Number, String],
        noicon: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'fas fa-search'
        },
        theme: {
            type: String,
            default: ''
        }
    },
    methods: {
        keyup() {
            this.$emit('keyup', event.target.value)
        },
        updateValue(event) {
            this.$emit('input', event.target.value)
        },
        search() {
            this.$emit('search')
        }
    }
}
</script>

<style scoped>
.products-page__input-title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.products-page__input {
  border: 3px solid #e5e5e5;
  border-radius: 8px;
  padding: 0.5em;
  width: 21vw;
  height: 6vh;
}
.products-page__input:focus {
  /* border: 1px solid #ff7110 !important; */
  border: 3px solid #e5e5e5 !important;
}
.products-page__input-icon {
  position: relative;
  left: -25px;
  color: #8F8F8F;
  border: none;
}

@media screen and (min-width: 1300px) {
  .products-page__input-icon {
    left: -35px;
  }
}

@media screen and (max-width: 970px) {
  .products-page__inputs-container {
    flex-direction: column;
  }
  .products-page__input {
    width: 100vw;
    font-size: 1.8em;
  }
}
@media screen and (max-width: 700px) {
  .products-page__input-title {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 500px) {
  .products-page__input {
    width: 60vw;
    font-size: 3em;
  }
  .products-page__input-title {
    font-size: 3em;
  }
}
</style>