<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="new-product__header-container">
                <span class="new-product__header-title">
                    {{ product.pro_descricao || '-' }}
                </span>
                <span @click="closeDialog" class="material-icons new-product__closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="brands-modal__product-title-wrapper">
                <span class="brands-modal__product-title"># Marca: {{ product.pro_observacao }}</span> <br>
            </div>
            <div class="brands-modal__actions-wrapper">
                <StandardInputAuto :placeholder="'Digite a marca'"
                                   :title="'Buscar marcas'"
                                   :action="setProductValue"
                                   class="brands-modal__actions-search"
                                   :type="'text'"/>
                <StandardButton v-if="!showAddBrand" class="mobile-button brands-modal-add-button" :action="() => showAddBrand = true" :iconName="'add'" :text="'Adicionar marca'"/>
                <StandardButton v-if="!showAddBrand" class="mobile-button" :action="closeDialog" :iconName="'arrow_back'" :text="'Voltar'"/>
                <StandardButton v-else class="mobile-button brands-modal-back-button" :action="() => showAddBrand = false" :iconName="'arrow_back'" :text="'Voltar'"/>
            </div>
            <div v-if="showAddBrand" class="page-table-container">
                <SearchV2 :placeholder="'Digite a nova marca'" :icon="'add'" v-model="new_brand"
                @search="save()" />
            </div>
            <div v-else class="page-table-container">
                <div class="page-table-content">
                    <div class="page-table-header">
                        <div class="page-table-header-text id-width">ID Padrão</div>
                        <div class="page-table-header-text brand-width">Marca</div>
                        <div class="page-table-header-text action-width">Ações</div>
                    </div>
                    <div v-for="(product, idx) in brands" :key="idx" :class="getSpecialBackground(idx)">
                        <div class="page-table-row">
                            <div class="page-table-desc-column id-width">{{ product.pro_id || '-' }}</div>
                            <div class="page-table-desc-column brand-width">{{ product.pro_observacao }}</div>
                            <div class="page-table-desc-column action-width">
                                <span v-if="product.isChecked" @click="removeBrandsCode(product)"
                                    class="material-icons-outlined checkbox-icon" style="color: var(--primary-color);">
                                    check_box
                                </span>
                                <span v-else class="material-icons-outlined checkbox-icon" style="color: var(--primary-color);"
                                    @click="storeBrandsCode(product)">
                                    check_box_outline_blank
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <cc-loader-full v-if="loading" />
    </div>
</template>

<script>
import ProductService from '@/services/v3/management/product.service';
import * as CONSTANTS from '@/constants/constants';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue';
import StandardSearch from '@/components/ui/inputs/StandardSearch.vue'
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import SearchV2 from '@/components/ui/inputs/search.componentV2.vue'

export default {
    mixins: [loaderMixin],
    emits: ['reload'],
    props: {
        product: {
            type: Object,
        },
        closeDialog: {
          type: Function,
        },
    },
    components: {
        StandardInputAuto,
        StandardSearch,
        StandardButton,
        SearchV2
    },
    data() {
        return {
            svc: new ProductService(),
            total: undefined,
            objective: undefined,
            generic: undefined,
            filters: {
                name: ""
            },
            total_items: 0,
            loading: false,
            productsList: [],
            brands: [],
            newProdutoPadraoValue: "",
            showAddBrand: false,
            new_brand: ''
        }
    },
    methods: {
        save() {
            this.loading = true
            this.svc.add_brand(this.product.pro_padrao_id_brand, this.new_brand, false, 1)
                .then(response => response.data).then(() => {
                    this.new_brand = ''
                    this.showAddBrand = false
                    this.showBrandsList()
                }).catch(error => {
                    this.loading = false
                })
        },
        storeBrandsCode(product) {
          this.brands.forEach(product => product.isChecked = false)

          this.updateProPadraoCode(product)

          if(product.isChecked == undefined || product.isChecked == false) {
            product.isChecked = true
            this.newProdutoPadraoValue = product.pro_id
          }
          product.isChecked = true
          this.$forceUpdate()
        },
        removeBrandsCode(product) {
          product.isChecked = false
          this.$forceUpdate()
        },
        removeProductCodes(product) {
            product.isChecked = false
            this.$forceUpdate()
        },
        storeProductCodes(product) {
            this.productsList.forEach(product => product.isChecked = false)

            this.updateProPadraoCode(product)

            if (product.isChecked == undefined || product.isChecked == false) {
                product.isChecked = true
                this.newProdutoPadraoValue = product.id
            }
            product.isChecked = true
            this.$forceUpdate()
        },
        updateProPadraoCode(product) {
            this.confirm_action({
                message: `Deseja atualizar o Pro Padrão do produto ${this.product.pro_descricao} para ${product.pro_id}?`,
                callback: () => {
                    const config = {
                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    }
                    const params = {
                        pro_id: this.product.pro_id,
                        pro_padrao_id: this.newProdutoPadraoValue
                    }
                    axios
                        .put(`${CONSTANTS.API_V3_URL}/admin/products/`, params, config)
                        .then(res => {
                            this.$swal.fire('Configurações Salvas')
                            this.closeDialog()
                            this.$emit('reload')
                        });
                }
            })
        },
        setProductValue(value) {
            this.filters.name = value
            setTimeout(() => {
                this.showBrandsList()
            }, 1000);
        },
        showBrandsList() {
            this.loading = true

            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {}
            };
            axios
                .get(`${CONSTANTS.API_V3_URL}/admin/management/products/${this.product.pro_padrao_id_brand}/getMarcasProduto`, config)
                .then(data => {
                    this.brands = data.data.produtos
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false;
                })
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
    },
    mounted() {
        this.showBrandsList()
    },
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-content-container {
    background-color: white;
    width: 75vw;
    height: 100vh;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.new-product__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.brands-modal-add-button{
    width: 25%;
}
.brands-modal-back-button{
    width: 35%;
}
.new-product__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.6em;
}

.new-product__closebtn-icon {
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}

.brands-modal__product-title-wrapper {
    padding: 2em;
}

.brands-modal__product-title {
    font-weight: 400;
    color: #606060;
    line-height: 22px;
    font-size: 1.2em;
}

.brands-modal__actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1em 2em;
}

.products-page__input {
    width: 30vw;
}

.page-table-container {
    margin: 2em;
}

.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    display: flex;
    padding: 1em 0em;
}

.page-table-header-text {
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    align-items: center;
}

.page-table-desc-column {
    padding: 0.5em 0.7em;
    text-align: left;
}

.page-table-line-special {
    background: #F7F7F7;
}

.id-width {
    width: 10%;
}

.brand-width {
    flex: 1;
}

.action-width {
    width: 12%;
    display: flex;
    align-items: center;
}

.checkbox-icon {
    cursor: pointer;
}

.brands-modal__actions-search {
    width: 43%;
}

.boxes-icon {
    position: relative;
    top: -1px;
    width: 1.3em;
    margin-left: 10px;
    cursor: pointer;
}
</style>