import http from "../http";
import Service from "../../Service";

/**
 * @type ProductService
 */
export default class CategoryService extends Service {

    list(params) {
		return http.get(`/admin/management/categories`, {
			headers: this.getHeaders(),
            params
		});
	}

}
